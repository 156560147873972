<template>
  <component :is="component.tag" v-bind="component.attrs" class="link-1">
    <slot></slot>
  </component>
</template>
<script>
export default {
  name: "NbPageFooterLink",
  props: {
    href: {
      type: String,
      default: "",
    },
    to: {
      type: [String, Object],
      default: "",
    },
  },
  computed: {
    component() {
      if (this.href) {
        return {
          tag: "a",
          attrs: {
            href: this.href,
            target: "_blank",
          },
        };
      }
      return {
        tag: "router-link",
        attrs: {
          to: this.to,
        },
      };
    },
  },
};
</script>
<style lang="scss" scoped></style>
