<template>
  <footer>
    <slot></slot>
  </footer>
</template>
<script>
export default {
  name: "NbPageFooter",
};
</script>
<style lang="scss" scoped>
footer {
  border-top: 1px solid var(--gray-10);
  padding: 1.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
</style>
