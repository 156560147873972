<template>
  <div>
    <p class="m-0">
      <slot></slot>
    </p>
  </div>
</template>
<script>
export default {
  name: "NbPageFooterText",
};
</script>
<style lang="scss" scoped></style>
