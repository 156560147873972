<template>
  <div class="contracts">
    <NbPageTitle
      :title="$t('contractsPage.title')"
      :subTitle="$t('contractsPage.subtitle')"
    />

    <NbTablev2
      tableOf="contracts"
      namespace="contracts-names"
      :fields="fields"
      :allFields="tableFields"
      @reloadFields="fields = $event"
    >
      <template #cell(beginning_date)="data">
        {{ data.item.beginning_date | formatDate }}
      </template>

      <template #cell(end_date)="data">
        {{ data.item.end_date | formatDate }}
      </template>

      <template #cell(actions)="data">
        <NbButton
          @click="
            downloadPdf(data.item.price_table_id, data.item.name, data.item.id)
          "
          variant="tertiary"
          icon="download"
        >
          {{ $t("contractsPage.downloadPdf") }}
        </NbButton>
        <NbButton
          @click="downloadCsv(data.item.price_table_id, data.item.name)"
          variant="tertiary"
          icon="download"
        >
          {{ $t("contractsPage.downloadCsv") }}
        </NbButton>
      </template>
    </NbTablev2>

    <NbPageFooter class="mt-4">
      <NbPageFooterText
        >{{ $t("components.footer.needHelp") }}?</NbPageFooterText
      >
      <NbPageFooterLink href="#"
        >{{ $t("contractsPage.footerHelpText") }}.</NbPageFooterLink
      >
    </NbPageFooter>
  </div>
</template>

<script>
import NbPageTitle from "../../components/pagescomponents/NbPageTitle.vue";
import NbTablev2 from "@/components/tables/NbTablev2.vue";
import NbButton from "@/components/buttons/NbButton.vue";
import PriceTablesService from "../../services/PriceTablesService.js";
import NbPageFooter from "../../components/footers/NbPageFooter.vue";
import NbPageFooterText from "../../components/footers/NbPageFooterText.vue";
import NbPageFooterLink from "../../components/footers/NbPageFooterLink.vue";
import moment from "moment";

const priceTablesService = new PriceTablesService();

export default {
  name: "account-contracts",
  filters: {
    formatDate(data) {
      return moment(data).format("DD/MM/YYYY");
    },
  },
  components: {
    NbPageTitle,
    NbTablev2,
    NbButton,
    NbPageFooter,
    NbPageFooterText,
    NbPageFooterLink,
  },
  data() {
    return {
      fields: [],
    };
  },
  computed: {
    tableFields() {
      return [
        { key: "name", label: this.$t("name") },
        { key: "incoterm", label: this.$t("contractsPage.inconterm") },
        { key: "beginning_date", label: this.$t("contractsPage.startDate") },
        { key: "end_date", label: this.$t("contractsPage.endDate") },
        { key: "taxing_type", label: this.$t("contractsPage.taxingType") },
        {
          key: "actions",
          isCustomizable: false,
          label: this.$t("quickActions"),
          class: "right--20 right-sticky",
        },
      ];
    },
  },
  methods: {
    downloadPdf(id, name, contract_id) {
      priceTablesService.downloadPdf(id, contract_id).then((response) => {
        if (response.data.data && response.data.data.file) {
          let file = response.data.data.file;
          let pdf = `data:application/pdf;base64,${file}`;
          let download_link = document.createElement("a");
          download_link.href = pdf;
          download_link.download = `price_table_${name}.pdf`;
          download_link.click();
        }
      });
    },
    downloadCsv(id, name) {
      priceTablesService.downloadCsv(id).then((response) => {
        let universalBOM = "\uFEFF";
        let csv =
          "data:text/csv; charset=utf-8," +
          encodeURIComponent(universalBOM + response.data);
        let filename = `price_table_${name}.csv`;
        let anchor = document.createElement("a");
        anchor.href = csv;
        anchor.target = "_blank";
        anchor.download = filename;
        anchor.click();
      });
    },
  },
};
</script>

<style lang="scss" scoped></style>
