import HttpService from "./HttpService";

export default class ContractService {
  constructor() {
    this.httpService = HttpService;
  }

  getPriceTables() {
    return this.httpService.get("/v1/price_tables");
  }

  delete(id) {
    return this.httpService.delete(`/v1/price_tables/${id}`);
  }

  downloadPdf(id, contract_id = null) {
    if (contract_id) {
      return this.httpService.get(
        `/v1/price_tables/${id}/download_pdf?contract_id=${contract_id}`
      );
    } else {
      return this.httpService.get(`/v1/price_tables/${id}/download_pdf`);
    }
  }

  downloadCsv(id) {
    return this.httpService.get(`/v1/price_tables/${id}/download_csv`);
  }
}
